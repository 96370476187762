#dialog-content {
  width: 300px;
  padding-bottom: 0px;
  margin-top: 10px;
}

#dialog-content-indeterminate {
  width: 300px;
  padding-bottom: 0px;
  margin: 16px 8px 40px;
}
